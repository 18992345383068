import styled from 'styled-components'

import DefaultH1 from '@bootstrap-styled/v4/lib/H1'
import DefaultH2 from '@bootstrap-styled/v4/lib/H2'
import DefaultH3 from '@bootstrap-styled/v4/lib/H3'
import DefaultH4 from '@bootstrap-styled/v4/lib/H4'
import DefaultH5 from '@bootstrap-styled/v4/lib/H5'
import DefaultH6 from '@bootstrap-styled/v4/lib/H6'

export const H1 = styled(DefaultH1)`
  font-family: TiemposHeadline;
  font-weight: bold;
  letter-spacing: .3px;
  @media (max-width: 920px) {
    font-size: 34px;
  }
`

export const H2 = styled(DefaultH2)`
  font-size: 24px;
`

export const H3 = styled(DefaultH3)`
  font-size: 18px;
`

export const H4 = styled(DefaultH4)`
  font-size: 18px;
`

export const H5 = styled(DefaultH5)`
  font-size: 16px;
`

export const H6 = styled(DefaultH6)`
  font-size: 16px;
`
