import Button from '@bootstrap-styled/v4/lib/Button'
import styled from 'styled-components'

export default styled(Button)`
  &.btn {
    font-weight: 500;
  }
  &.btn-primary:hover, &.btn-primary.hover {
    background: #0036cb;
  }
`
