import Link from 'next/link'
import A from '@bootstrap-styled/v4/lib/A'
import React from 'react'
import Button from '../Button'
import styled from 'styled-components'
import Container from '@bootstrap-styled/v4/lib/Container'
import Bar from '@bootstrap-styled/v4/lib/Navbar'
import Brand from '@bootstrap-styled/v4/lib/Navbar/NavbarBrand'
import NavbarToggler from '@bootstrap-styled/v4/lib/Navbar/NavbarToggler'


const NavBarStyled = styled(Bar)`
  &.navbar {
    background-color: #ffffff;
    border-bottom: 1px solid #e8e8e8;
    padding: 9px 1rem;
    @media (max-width: 920px) {
      margin-bottom: 18px;
    }
  }
  .collapse {
    width: auto !important;
  }
  @media (max-width:992px) {
    .collapse {
      // flex: 1;
      order: 999;
      width: 100% !important;
      text-align: center;
    }
  }
  .container-fluid {
    align-items: center;
    @media (max-width: 920px) {
      padding: 0;
    }
  }
  &.fixed-true {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1001;
  }
`
const NavbarBrand = styled(Brand)`
  &.navbar-brand {
    font-family: Gilroy;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    padding: 20.5px 0;
    margin: 0;
    @media (max-width: 920px) {
      font-size: 18px;
    }
  }
`
const NavBarContainer = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  justify-content: between;
`
const NavBarDivider = styled('div')`
  &::before {
    display: block;   
    width: 1px;
    height: 62px;
    border-right: 1px solid #e8e8e8;
    margin: 0 32px;
    content: ' ';
  }
`

export function NavBar ({ title = 'Local Physio Network', children, ...props }) {
  const [navState, setNavState] = React.useState(false)

  return (
    <NavBarStyled light toggleable={'lg'} {...props}>
      <NavBarContainer fluid>
        <Link passHref href="/">
          <NavbarBrand tag={A}>{title}</NavbarBrand>
        </Link>
        <NavBarDivider className={'d-none d-lg-block'}/>
        { children }
        <Toggler onClick={() => setNavState(!navState)} className={'d-block ml-auto ml-lg-0'}/>
      </NavBarContainer>
    </NavBarStyled>
  )
}

export default NavBar

export const Toggler = styled(NavbarToggler)`
  &.navbar-toggler {
    align-self: center;
    padding: 11.5px .75rem;
    display: block;
    margin: 0;
    border: 0;
    color: #fff;
  }
`
